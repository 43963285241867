import React, { useState } from 'react';
import '../components/Shop.scss';
import whiteT from '../img/koszulki/2.png';
import blackT from '../img/koszulki/3.png';
import blueT from '../img/koszulki/4.png';


const Shop = () => {
  const [selectedColor, setSelectedColor] = useState('white');

  // Mapowanie koloru na odpowiedni obraz koszulki
  const tshirtImages = {
    white: whiteT,
    black: blackT,
    blue: blueT,
  };

  const colors = ['white', 'black', 'blue'];
  const texts = ['...JESZCZE TRZY', 'TARAPATY', 'OGIEŃ W SZOPIE'];
  const sizeRows = [
    { iconClass: 'fa-solid fa-person-dress', sizes: ['S', 'M', 'L', 'XL'] },
    { iconClass: 'fa-solid fa-person', sizes: ['S', 'M', 'L', 'XL', 'XXL'] }
  ];

  return (
    <div className="tshirt-customizer-wrapper">
    {/* Nagłówek na samej górze komponentu */}
    <h1 className="component-header">Dostępne w studio</h1>

    {/* Sekcja z podglądem koszulki */}
    <div className="tshirt-preview-container">
      <img 
        src={tshirtImages[selectedColor]} 
        alt="Koszulka" 
        className="tshirt-image" 
      />
      <div className="price-info">Cena: 80 zł</div>
    </div>

    {/* Sekcja z opcjami wyboru */}
    <div className="tshirt-options-container">
      {/* Opcje wyboru koloru */}
      <div className="color-options">
        <h3>Kolory koszulek:</h3>
        <div className="color-circle-container">
          {colors.map((color) => (
            <div
              key={color}
              className={`color-circle ${color} ${selectedColor === color ? 'active' : ''}`}
              onClick={() => setSelectedColor(color)}
            />
          ))}
        </div>
      </div>

      {/* Informacja o rozmiarach */}
      <div className="size-info">
        <h4>Dostępne rozmiary:</h4>
        {sizeRows.map((row, index) => (
          <div key={index} className="size-row">
            {/* Dodanie odpowiedniej ikony */}
            <i className={row.iconClass}></i>
            <span>{row.sizes.join(', ')}</span>
          </div>
        ))}
      </div>

      {/* Wyświetlanie dostępnych tekstów (bez interakcji) */}
      <div className="text-info">
        <h3>Dostępne napisy:</h3>
        {texts.map((text) => (
          <div key={text} className="text-option">
            {text}
          </div>
        ))}
      </div>
    </div>
  </div>
);
};

export default Shop;
