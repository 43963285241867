import React, { useEffect, useRef } from "react";
import "../components/Map.scss";

const MapAndContact = () => {
  const mapRef = useRef(null);
  const libInjectionRequired = useRef(!Boolean(window.google));
  const libLoading = useRef(false);

  useEffect(() => {
    const initMap = () => {
      const map = new window.google.maps.Map(mapRef.current, {
        center: { lat: 51.26794530060789, lng: 15.562610912611914 },
        zoom: 15,
        styles: [
          {
            featureType: "all",
            elementType: "all",
            stylers: [{ saturation: -100 }, { lightness: 50 }],
          },
        ],
      });

      // Użycie google.maps.Marker
      new window.google.maps.Marker({
        position: { lat: 51.26794530060789, lng: 15.562610912611914 },
        map: map,
      });
    };

    if (libInjectionRequired.current) {
      if (libLoading.current) return;
      libLoading.current = true;

      window.initMap = initMap;

      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&callback=initMap`;
      script.async = true;
      script.defer = true;

      script.onload = () => {
        initMap();
      };

      document.head.appendChild(script);
    } else {
      initMap();
    }
  }, []);

  return (
    <div className="map-contact-container">
      <div
        id="map"
        ref={mapRef}
        className="map-contact-map-container"
      ></div>

      <div className="map-contact-contact-container">
        <h2>Jak do nas trafić?</h2>
        <div className="map-contact-contact-info">
          <p>
            <strong>Adres:</strong>
          </p>
          <p>Staszica 9, 59-700 Bolesławiec</p>
        </div>
        <div className="map-contact-hours">
          <h3>Godziny otwarcia</h3>
          <p>Dostosowujemy się do potrzeb klienta!</p>
        </div>
      </div>
      <div className="map-shadow"></div>
    </div>
  );
};

export default MapAndContact;
