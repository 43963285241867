import React from "react";
import Header from "../components/Header";
import NavBar from "../components/NavBar";
import AboutUs from "../components/AboutUs";
import TrainerCarousel from "../components/CoWorkers";
import { trainers } from "../treiners/trainers";
import { reviews } from "../treiners/opinions";
import Opinions from "../components/Opinions";
import MapAndContact from "../components/Map";
import Footer from "../components/Footer";
import MessageIcon from "../components/Icon";

const Home = () => {
	return (
		<>
			
				
				<Header></Header>
				<AboutUs></AboutUs>
				<TrainerCarousel trainers={trainers}></TrainerCarousel>
				<Opinions reviews={reviews}></Opinions>
				<MapAndContact></MapAndContact>
			
			
			
		</>
	);
};

export default Home;
