import React from "react";
import TrainerCard from "../components/TrainerCard";

import { trainers } from "../treiners/trainers";

import { useParams, useLocation } from "react-router-dom";

const Squad = () => {
	const { id } = useParams(); // Pobierz id z parametru trasy
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const queryId = queryParams.get("id"); // Pobierz id z parametrów zapytania

	const selectedTrainer = trainers.find(
		(trainer) => trainer.id === parseInt(id) || trainer.id === parseInt(queryId)
	); // Sprawdź, czy id jest w danych

	return (
		<div>
			{selectedTrainer ? (
				<TrainerCard trainers={[selectedTrainer]} /> // Wyświetl tylko wybranego trenera
			) : (
				<TrainerCard trainers={trainers} /> // Wyświetl wszystkich trenerów
			)}
		</div>
	);
};

export default Squad;
