import React, { useEffect, useState } from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Opinions.scss";
export default function Opinions({ reviews }) {
	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1260 },
			items: 2,
		},
		tablet: {
			breakpoint: { max: 1260, min: 464 },
			items: 1,
			slidesToSlide: 2, // optional, default to 1.
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			slidesToSlide: 1, // optional, default to 1.
		},
	};

	return (
		<div className="reviews section-padding section white-section">
            <h2 className="section-title">OPINIE</h2>
            <div className="underline"></div>
		
				<div className="reviews-boxes">
					<Carousel
						responsive={responsive}
						autoPlay={true}
						autoPlaySpeed={3000} // Czas przewijania w ms
						infinite={true}
						arrows={false} // Ukrywa strzałki nawigacyjne
                        containerClass="carousel-container"
					>
						{reviews.map((reviews, index) => (
							<div key={index} className="review-box">
								<div className="reviews-box-img">
									<img src={reviews.profile_photo_url} alt="" />
								</div>
								<div className="reviews-box-info">
									<div className="reviews-box-quote">
										<i className="fas fa-quote-right"></i>
									</div>
									<p className="reviews-box-text">{reviews.text}</p>
									<strong>{reviews.author_name}</strong>
								</div>
							</div>
						))}
					</Carousel>
				</div>{" "}
			</div>
		
	);
}
