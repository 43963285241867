import React from 'react';

import '../components/Icon.scss'; // Import stylów SCSS

const MessageIcon = ({onClick}) => {
  return (
   
    <div className="message-icon" onClick={onClick}>
    {/* Zmień ścieżkę na odpowiednią dla formularza kontaktowego */}
      <i className="fa-regular fa-envelope"></i>
      
    </div>
    
  );
};

export default MessageIcon;