import React, { useState } from "react"; // Dodaj useState
import { Link } from "react-router-dom"; // Importuj Link z react-router-dom
import "./NavBar.scss";
import logo from "../img/LOGO2.png";

const NavBar = ({ toggleBanner }) => {
	const [isNavOpen, setIsNavOpen] = useState(false); // Stan do kontrolowania otwarcia menu

	const toggleNav = () => {
		setIsNavOpen(!isNavOpen); // Zmiana stanu po kliknięciu
	};

	const handleLinkClick = (e) => {
		setIsNavOpen(false);
	};

	const bannerToggler = (e) => {
		e.preventDefault(); // Zapobiega domyślnemu zachowaniu Link
		toggleBanner(); // Przełącz widoczność banera po kliknięciu
	};

	return (
		<nav className="navbar navbar-expand-lg position-sticky top-0">
			<div className="container">
				<Link className="navbar-brand" to="/">
					{" "}
					{/* Zmiana href="#" na to="/" */}
					<img src={logo} alt="Logo"></img>
				</Link>
				<button
					className="navbar-toggler"
					type="button"
					onClick={toggleNav} // Dodano obsługę kliknięcia
					aria-controls="navbarNav"
					aria-expanded={isNavOpen} // Ustawienie atrybutu aria
					aria-label="Toggle navigation">
					<span className="navbar-toggler-icon"></span>
				</button>
				<div
					className={`collapse navbar-collapse ${isNavOpen ? "show" : ""}`}
					id="navbarNav">
					{" "}
					{/* Dodano 'show' do klasy */}
					<ul className="navbar-nav ms-auto">
						<li className="nav-item">
							<Link className="nav-link" onClick={toggleBanner}>
								{" "}
								{/* Dodano onClick */}
								AKTUALNOŚCI
							</Link>
						</li>
						<li className="nav-item">
							<Link
								className="nav-link"
								to="/gallery"
								onClick={handleLinkClick}>
								{" "}
								{/* Dodano onClick */}
								GALERIA
							</Link>
						</li>
						<li className="nav-item">
							<Link
								className="nav-link"
								to="/blog"
								onClick={handleLinkClick}>
								{" "}
								{/* Dodano onClick */}
								BLOG
							</Link>
						</li>
						<li className="nav-item">
							<Link
								className="nav-link"
								to="/trainers"
								onClick={handleLinkClick}>
								{" "}
								{/* Dodano onClick */}
								ZESPÓŁ
							</Link>
						</li>
						<li className="nav-item">
							<Link
								className="nav-link"
								to="/pricing"
								onClick={handleLinkClick}>
								{" "}
								{/* Dodano onClick */}
								CENNIK
							</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to="/shop" onClick={handleLinkClick}>
								{" "}
								{/* Dodano onClick */}
								SKLEP
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default NavBar;
