import img from "../img/Ada.jpg";
import bartbig from "../img/trenerzy/BartoszSmoliński1.jpg";
import bartsmall from "../img/trenerzy/Bartek.png";
import natalia1 from "../img/trenerzy/Natalia.png";
import natalia3 from "../img/trenerzy/NataliaPrzybyło4.jpg";
import amek from "../img/trenerzy/Amek.png";
import amek2 from "../img/trenerzy/Amadeusz3.jpg";
import aleksandra from "../img/trenerzy/Ola.png";
import aleksandra1 from "../img/trenerzy/AleksandraWróbeldietetyk5.jpg";
import robert from "../img/trenerzy/Robert.png";
import robert1 from "../img/trenerzy/Robert4.jpg";
import alex from "../img/trenerzy/Alexander5.jpg";
import alex1 from "../img/trenerzy/Alex.png";
import bartcar from "../img/trenerzy/BartoszSmoliński2.jpg";
import nataliacar from "../img/trenerzy/NataliaPrzybyło.jpg";
import amekcar from "../img/trenerzy/Amadeusz1.jpg";
import aleksandracar from "../img/trenerzy/AleksandraWróbe1.jpg";
import robertcar from "../img/trenerzy/Robert.jpg";
import alex1car from "../img/trenerzy/Alexand1.jpg";

export const trainers = [
	{
		id: 0,
		name: "Amadeusz Dziemianko",
		role: "Trener Personalny",
		description: `Założyciel Bolesławieckiego Centrum Ruchu.\n
Absolwent Akademii Wychowania Fizycznego we Wrocławiu.\n
Trener od 2016 roku. Swoją trenerską przygodę rozpoczął już podczas studiów we Wrocławiu, gdzie od pracy jako instruktora przeszedł do prowadzenia tylko i wyłącznie treningów indywidualnych.\n
Odbył szereg szkoleń związanych z treningiem siłowym, rehabilitacją czy przygotowaniem motorycznym.
W swojej pracy kładzie nacisk na dogłębne analizowanie i planowanie każdego procesu, starając się maksymalizować efekty swoich klientów.\n
Pomógł wielu osobom zniwelować ból, pozbyć się kontuzji, utracić zbędne kilogramy i zbudować wymarzone sylwetki.
Na co dzień pracuje z osobami rekreacyjnie trenującymi oraz ze sportowcami (takimi jak piłka nożna, lekkoatletyka, sporty walki, biegi długodystansowe oraz jazda na deskorolce).\n
Pasjonat oraz czynny zawodnik trójboju siłowego.
Współtwórca jednego z większych projektów w kraju, jeżeli chodzi o szeroko rozumiany trening siłowy, czyli projekt A-Game.
W wolnym czasie lubi eksperymentować w kuchni oraz wypić dobrą kawę.`,
		type: "Trener Personalny ",
		imagecar: amekcar,
		image: amek,
		background: amek2,
		instagram: "https://www.instagram.com/trener_amek", // Link do Instagrama
	},
	{
		id: 1,
		name: "Bartosz Smoliński",
		role: "Trener Personalny",
		description: `Zastanawiasz się, dlaczego powinieneś wybrać właśnie mnie?\n
Nie będę udawał, że sport był moją pasją od zawsze. Wręcz przeciwnie – jako nastolatek unikałem lekcji WFu, co doprowadziło mnie do imponującej wagi prawie 120 kg i zgromadzenia siły… która nie wystarczała nawet na zrobienie jednej pompki. Wtedy postanowiłem coś zmienić i zacząłem przygodę z aktywnością fizyczną. Najpierw była koszykówka, która w moim przypadku zakończyła się serią skręconych kostek i naderwanym więzadłem rzepki. Następnie spróbowałem siłowni, gdzie z kolei doznałem kontuzji obu barków i pogorszyłem stan dyskopatii, którą już wcześniej miałem.\n
Czy był to pech? A może brak wiedzy i wsparcia specjalisty? Niezależnie od przyczyny, te doświadczenia nauczyły mnie, jak ważna jest właściwa technika i indywidualne podejście do treningu.\n
Dlatego teraz jestem tutaj, by pomóc Ci uniknąć tych błędów. Jeśli zdecydujesz się na współpracę ze mną, mogę Ci pomóc:\n
- wyjść z kontuzji i wrócić do pełnej sprawności,
- wrócić do formy po operacji,
- schudnąć i zbudować wymarzoną sylwetkę,
- przygotować się do testów sprawnościowych,
- lub po prostu poprawić samopoczucie i ogólną sprawność fizyczną.\n
Wspólnie zadbamy o to, aby Twoja droga do lepszej formy była bezpieczna, przemyślana i skuteczna.\n
Do zobaczenia w Bolesławieckim Centrum Ruchu!`,
		type: "trener",
		image: bartsmall,
		imagecar: bartcar,

		background: bartbig,
		instagram: "https://www.instagram.com/smolinski_barti", // Link do Instagrama
	},
	{
		id: 2,
		name: "Natalia Przybyło",
		role: "Trener Personalny",
		description:
			"Cześć, mam na imię Natalia, jestem trenerem personalnym oraz psychodietetykiem. W BCR zajmuje się prowadzeniem treningów personalnych oraz grupowych, a także wspomaganiem pacjentów od strony żywienia i wszystkich aspektów z tym związanych. Pomagam usunąć dolegliwości bólowe ze strony układu ruchu, pokonać dysfunkcje czy też zredukować masę ciała. Chętnie pomogę przygotować się do ciąży lub wrócić do sprawności po niej. Serdecznie zapraszam również na konsultacje dietetyczne w zakresie zaburzeń odżywiania czy też innych problemów rzutujących na układ pokarmowy.",
		type: "dietetyk",
		image: natalia1,
		imagecar: nataliacar,

		background: natalia3,
		instagram: "https://www.instagram.com/nat.trenerka", // Link do Instagrama
	},

	{
		id: 3,
		name: "Aleksandra Wróbel",
		role: "Dietetyk",
		description: `Nazywam się Ola i jestem dyplomowanym dietetykiem klinicznym. 
Na co dzień pracuje w szpitalu, głównie na oddziale chorób wewnętrznych, oddziale neurologicznym, pediatrii i stacji dializ gdzie między innymi przeprowadzam konsultacje dietetyczne i edukacje żywieniowe z pacjentami oraz ich rodzinami. 
Prowadzę również własny gabinet dietetyczny i przyjmuje pacjentów stacjonarnie w @studio_bcr oraz online.
Specjalizuję się w dietoterapii otyłości, chorób metabolicznych, chorób nerek oraz chorób układu pokarmowego.
Uwielbiam swoją pracę, a zadowolenie moich pacjentów przekłada się na jeszcze większą motywację do działania! 
Prywatnie - czytam książki, dużo spaceruję z psem i uwielbiam wszelkiego rodzaju aktywności fizyczne: bieganie, trening siłowy, trening fitness, basen, jazda na rowerze.`,
		type: "Dietetyk",
		image: aleksandra,
		imagecar: aleksandracar,

		background: aleksandra1,
		instagram: "https://www.instagram.com/a_wrobel.dietoterapia", // Link do Instagrama
	},
	{
		id: 4,
		name: "Robert Mistal",
		role: "Trener Personalny",
		description: `Cześć, nazywam się Robert i jestem certyfikowanym trenerem personalnym specjalizującym się w treningu z wolnymi ciężarami, kettlebell oraz ćwiczeniach z workiem bułgarskim. Moją misją jest pomaganie osobom w średnim wieku (i nie tylko) w odzyskaniu siły, mobilności oraz poprawie ogólnej kondycji – niezależnie od tego, ile masz lat i jak długo nie trenowałaś.\n
Rozumiem, że czasami przerwa w aktywności fizycznej może wydawać się dużą barierą, ale pamiętaj, że nigdy nie jest za późno, by wrócić do formy. Pracuję z osobami, które chcą zadbać o swoje zdrowie, poprawić wygląd sylwetki i zyskać więcej energii na co dzień. Moje treningi są dostosowane do Twoich indywidualnych potrzeb i możliwości, dzięki czemu możemy wspólnie osiągać Twoje cele krok po kroku.\n
Jeśli chcesz wzmocnić swoje ciało, poprawić swoją kondycję i czuć się lepiej każdego dnia, zapraszam Cię do współpracy. Razem zrobimy to w bezpieczny, efektywny i przede wszystkim przyjemny sposób! – krew, pot i łzy.`,
		type: "Trener Personalny",
		image: robert,
		imagecar: robertcar,

		background: robert1,
		instagram: "https://www.instagram.com/robert_mistal_", // Link do Instagrama
	},
	{
		id: 5,
		name: "Alexander Kwaśnik",
		role: "Fizjoterapeuta",
		description: `Jestem absolwentem Akademii Wychowania Fizycznego we Wrocławiu na wydziale Fizjoterapii oraz certyfikowanym trenerem personalnym ze specjalizacją w treningu medycznym. Od 2015 roku czynnie pracuję z pacjentami w prywatnych klinikach fizjoterapeutycznych, klubach sportowych czy siłowniach.\n

Na co dzień pracuję z pacjentami urazowo- ortopedycznymi, sportowcami (sporty zespołowe, sporty walki, sporty indywidualne, na poziomie olimpijskim jak i amatorskim), z pacjentami przewlekle bólowymi, z pacjentami ze schorzeniami układu limfatycznego (po operacjach plastycznych, resekcji gruczołu piersiowego, amputacjach).
Zajmuję się usprawnianiem ruchowym pacjentów z chorobami metabolicznymi (cukrzyca, osteoporoza), niewydolnością serca czy pacjentami pulmonologicznymi.\n

Przykładowe dysfunkcje, z którymi pracuję, u pacjentów ortopedycznych: uszkodzenia stożka rotatorów, łokieć tenisisty, łokieć golfisty, cieśń nadgarstka, endoproteza biodra, endoproteza kolana, endoproteza barku, biodro trzaskające, stan po operacji haluksów, stan po operacji więzadeł w obrębie kończyn dolnych, dyskopatia, bóle głowy, wspomagam proces leczenia stomatologiczno- ortodontycznego.\n

Przeprowadzam konsultacje ukierunkowane na optymalizację ruchu, poprawę biomechaniki w sporcie zawodowym jak i amatorskim. Analizuję sposób poruszania się, chód, bieg, specyficzny element ruchowy w sporcie czy wzorce ruchowe w podstawowym treningu siłowo- wytrzymałościowym. Zajmuję się doborem ćwiczeń akcesoryjnych pod dysfunkcje układu ruchu, optymalizuje plany treningowe uwzględniając potrzeby sportowe jak i zdrowotne`,
		type: "Fizjoterapeuta",
		image: alex1,
		imagecar: alex1car,

		background: alex,
		instagram: "https://www.instagram.com/alex_fizjologic", // Link do Instagrama
	},
];
