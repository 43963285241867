import React, { useState } from "react";
import "../components/Gallery.scss";
import fot1 from '../img/galery/2.jpg'
import fot2 from '../img/galery/1.jpg'
import fot3 from '../img/galery/3.jpg'
import fot4 from '../img/galery/4.jpg'
import fot5 from '../img/galery/5.jpg'
import fot6 from '../img/galery/6.jpg'
import fot7 from '../img/galery/7.jpg'
import fot8 from '../img/galery/8.jpg'
import film from '../img/galery/film.mp4'


const Gallery = () => {
    // Lista obrazów i wideo
    const images = [
        { id: 1, src: fot1, alt: "Obraz 1" },
        { id: 2, src: fot2, alt: "Obraz 2" },
        { id: 3, src: fot3, alt: "Obraz 3" },
        { id: 4, src: fot4, alt: "Obraz 4" },
        { id: 5, src: fot5, alt: "Obraz 5" },
        { id: 6, src: fot6, alt: "Obraz 5" },
        { id: 7, src: fot7, alt: "Obraz 5" },
        { id: 8, src: fot8, alt: "Obraz 5" },

        // Możesz dodać więcej obrazów
    ];

    // Stan do przechowywania aktualnie wyświetlanej zawartości (domyślnie wideo)
    const [currentContent, setCurrentContent] = useState("video");
    const [activeThumbnailIndex, setActiveThumbnailIndex] = useState(null);
    const [fade, setFade] = useState(false); // Stan do zarządzania animacją

    const handleThumbnailClick = (index, src) => {
        setFade(true); // Włącz animację zanikania

        // Użyj timeout, aby przełączyć obraz po zakończeniu animacji
        setTimeout(() => {
            setCurrentContent(src); // Zmień na wybrane zdjęcie
            setActiveThumbnailIndex(index); // Ustaw aktywny indeks miniatury
            setFade(false); // Wyłącz animację
        }, 300); // Czas trwania animacji (300ms)
    };

    return (
        <div className="gallery">
            <div className={`main-display ${fade ? 'fade-out' : ''}`}>
                {currentContent === "video" ? (
                 <video width="100%" height="400px" controls>
                 <source src={film} type="video/mp4" />
                 Twoja przeglądarka nie obsługuje tagu wideo.
               </video>
                ) : (
                    <img src={currentContent} alt="Wybrane" className="main-image" />
                )}
            </div>

            {/* Karuzela z miniaturami */}
            <div className="thumbnail-carousel">
                {images.map((image, index) => (
                    <div
                        key={image.id}
                        className={`thumbnail ${activeThumbnailIndex === index ? 'active-thumbnail' : ''}`} // Zastosuj klasę aktywną
                        onClick={() => handleThumbnailClick(index, image.src)}
                    >
                        <img src={image.src} alt={image.alt} className="thumbnail-image" />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Gallery;
