import React, { useEffect, useState } from "react";
import "../components/InfoBaner.scss";
import banner1 from "../img/tlotrener.jpg";
import banner2 from "../img/tlofizjo.jpg";
import logo from '../img/LOGO2.png'

const banners = [
  {
    title: "SZUKAMY!",
    subtitle: "TRENERA PERSONALNEGO",
    backgroundImage: `url(${banner1})`,
    contactPhone: "+48 535 202 813",
    contactEmail: "amadeuszdziemianko@gmail.com",
  },
  {
    title: "SZUKAMY!",
    subtitle: "FIZJOTERAPEUTY",
    backgroundImage: `url(${banner2})`,
    contactPhone: "+48 535 202 813",
    contactEmail: "amadeuszdziemianko@gmail.com",
  },
];

const InfoBanner = () => {
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % banners.length);
        setIsAnimating(false);
      }, 1000);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  const currentBanner = banners[currentBannerIndex];

  return (
    <div className="info-banner">
      <div
        className={`content-wrapper ${isAnimating ? "slide-out" : "slide-in"}`}
        style={{ backgroundImage: currentBanner.backgroundImage }}>
        <div className="banner-content">
          {/* Dodanie napisu nad głównym tytułem */}
          <p className="team-join">DOŁĄCZ DO ZESPOŁU BCR</p>

          <h2 className="highlight">{currentBanner.title}</h2>
          <p className="subtitle">{currentBanner.subtitle}</p>
          <div className="contact-info">
            <h4>Kontakt:</h4>
            <a className="number" href={`tel:${currentBanner.contactPhone}`}>
              📞 {currentBanner.contactPhone}
            </a>
            <a className="email-link" href={`mailto:${currentBanner.contactEmail}`}>
               {currentBanner.contactEmail}
            </a>
          </div>
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>

        
        
        </div>
      </div>
    </div>
  );
};

export default InfoBanner;
