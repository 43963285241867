import React, { useState, useEffect } from "react";
import "../components/Pricing.scss";

const Services = () => {
  const [activeCategory, setActiveCategory] = useState(null);
  const [showPriceList, setShowPriceList] = useState(false);

  const services = {
    trening: [
      { name: "Trening personalny", price: "130 zł" },
      { name: "Pakiet 8 treningów", price: "960 zł" },
      { name: "Pakiet 12 treningów personalnych", price: "1320 zł" },
      { name: "Trening personalny duo", price: "180 zł" },
      { name: "Pakiet 8 treningów personalnych duo", price: "1360 zł" },
      { name: "Pakiet 12 treningów personalnych duo", price: "1920 zł" },
      { name: "Zajęcia 4-osobowa grupa 2x w tygodniu", price: "400 zł" },
      { name: "Zajęcia 4-osobowa grupa 3x w tygodniu", price: "500 zł" },
      { name: "Plan treningowy", price: "300 zł" },
    ],
    dietetyka: [
      { name: "Pierwsza konsultacja dietetyczna (stacjonarnie)", price: "150 zł" },
      { name: "Pierwsza konsultacja dietetyczna (online)", price: "130 zł" },
      { name: "Indywidualny plan żywieniowy na 14 dni", price: "200 zł" },
      { name: "Indywidualny plan żywieniowy na 7 dni", price: "120 zł" },
    ],
    fizjoterapia: [
      { name: "Fizjoterapia od", price: "170 zł" },
      { name: "Fizjoterapia od (bardziej złożone problemy)", price: "190 zł" },
    ],
  };

  // Ustawia aktywną kategorię i pokazuje cenę
  const handleTileClick = (category) => {
    setActiveCategory(category);
    setShowPriceList(true);
  };

  // Ukrywa cennik po kliknięciu poza kafelkami
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".service-tile")) {
        setShowPriceList(false);
        setActiveCategory(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="services-wrapper">
      {/* Centralny tytuł */}
      <div className="services-header">
        <h1>OSIĄGNIJ</h1>
        <h3>Swój Cel</h3>
      </div>

      {/* Kafelki w jednej linii */}
      <div className="services-container">
        {Object.keys(services).map((category) => (
          <div
            key={category}
            className={`service-tile ${category} ${activeCategory === category ? "active" : ""}`}
            onClick={() => handleTileClick(category)}
          >
            <div className="service-title">{category.toUpperCase()}</div>
          </div>
        ))}
      </div>

      {/* Panel cenowy wysuwany z lewej strony */}
      <div className={`price-list-panel ${showPriceList ? "show" : ""}`}>
        {activeCategory && (
          <div className="price-list-content">
            <h2>{activeCategory.toUpperCase()} - Cennik</h2>
            {services[activeCategory].map((item, index) => (
              <div key={index} className="service-item">
                <span>{item.name}</span>
                <span className="price">{item.price}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Services;
