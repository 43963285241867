import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../components/CoWorkers.scss";

const TrainerCarousel = ({
	trainers,
	autoPlay = true,
	autoPlaySpeed = 3000,
}) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const carouselRef = useRef(null);
	const navigate = useNavigate();

	const nextSlide = () => {
		setCurrentIndex((prevIndex) => (prevIndex + 1) % trainers.length);
	};

	const handleTrainerClick = (trainerId) => {
		// Przekieruj do strony `/trainers` z id trenera jako parametrem
		navigate(`/trainer/${trainerId}`); // Używamy parametru w URL
	};

	useEffect(() => {
		if (autoPlay) {
			const autoPlayTimer = setInterval(nextSlide, autoPlaySpeed);
			return () => clearInterval(autoPlayTimer); // Czyszczenie interwału
		}
	}, [autoPlay, autoPlaySpeed]);

	return (
		<div className="carousel-container-one" ref={carouselRef}>
			<div
				className="carousel-track"
				style={{
					transform: `translateX(${
						-currentIndex *
						(100 /
							(window.innerWidth > 1024 ? 4 : window.innerWidth > 768 ? 2 : 1))
					}%)`,
					transition: "transform 1s ease",
				}}>
				{trainers.map((trainer) => (
					<div key={trainer.id} className="carousel-slide">
						<div
							className="trainer-card"
							onClick={() => handleTrainerClick(trainer.id)}>
							<img
								src={trainer.imagecar}
								alt={trainer.title}
								className="trainer-image"
							/>
							<div className="trainer-info">
								<div className="trainer-name-instagram">
									<h4>{trainer.name}</h4>
									<a href={trainer.instagram} target="_blank" rel="noreferrer">
										<i className="fa-brands fa-instagram"></i>
									</a>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
			{/* Możesz dodać strzałki nawigacyjne */}
		</div>
	);
};

export default TrainerCarousel;
