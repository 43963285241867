// CookieConsent.jsx
import React, { useState, useEffect } from "react";
import "../components/CokieConset.scss";

const CookieConsent = () => {
	const [isVisible, setIsVisible] = useState(false);
	const [isSettingsOpen, setIsSettingsOpen] = useState(false);
	const [preferences, setPreferences] = useState({
		necessary: true,
		analytics: false,
		marketing: false,
	});

	useEffect(() => {
		const consent = JSON.parse(localStorage.getItem("cookieConsent"));
		if (!consent) {
			setIsVisible(true);
		} else {
			setPreferences(consent);
		}
	}, []);

	const acceptAllCookies = () => {
		const consent = { necessary: true, analytics: true, marketing: true };
		localStorage.setItem("cookieConsent", JSON.stringify(consent));
		setPreferences(consent);
		setIsVisible(false);
	};

	const rejectAllCookies = () => {
		const consent = { necessary: true, analytics: false, marketing: false };
		localStorage.setItem("cookieConsent", JSON.stringify(consent));
		setPreferences(consent);
		setIsVisible(false);
	};

	const savePreferences = () => {
		localStorage.setItem("cookieConsent", JSON.stringify(preferences));
		setIsVisible(false);
		setIsSettingsOpen(false);
	};

	const toggleSetting = (type) => {
		setPreferences((prev) => ({
			...prev,
			[type]: !prev[type],
		}));
	};

	const openSettings = () => {
		setIsSettingsOpen(true);
	};

	const closeSettings = () => {
		setIsSettingsOpen(false);
	};

	if (!isVisible) return null;

	return (
		<div className="cookie-consent">
			{isSettingsOpen ? (
				<div className="cookie-settings">
					<h3>Ustawienia ciasteczek</h3>
					<label>
						<input type="checkbox" checked={preferences.necessary} disabled />
						Niezbędne (wymagane)
					</label>
					<label>
						<input
							type="checkbox"
							checked={preferences.analytics}
							onChange={() => toggleSetting("analytics")}
						/>
						Analityczne
					</label>
					<label>
						<input
							type="checkbox"
							checked={preferences.marketing}
							onChange={() => toggleSetting("marketing")}
						/>
						Marketingowe
					</label>
					<div className="buttons">
						<button onClick={savePreferences}>Zapisz ustawienia</button>
						<button onClick={closeSettings}>Anuluj</button>
					</div>
				</div>
			) : (
				<div className="cookie-message">
					<p>
						Ta strona używa plików cookies, aby zapewnić najlepsze
						doświadczenie. Możesz zaakceptować wszystkie ciasteczka lub
						dostosować ustawienia.
					</p>
					<div className="buttons">
						<button onClick={acceptAllCookies}>Akceptuj wszystkie</button>
						<button onClick={rejectAllCookies}>Odrzuć wszystkie</button>
						<button onClick={openSettings}>Ustawienia ciasteczek</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default CookieConsent;
