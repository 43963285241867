import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../components/ContactModal.scss';
import logo from '../img/LOGO2.png';

const ContactModal = ({ isOpen, onClose }) => {
  const [step, setStep] = useState(1);
  const [specialist, setSpecialist] = useState('');
  const [trainer, setTrainer] = useState('');
  const [customMessage, setCustomMessage] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactName, setContactName] = useState('');
  const [recipientEmail, setRecipientEmail] = useState('');
  const [errors, setErrors] = useState({});

  // Mapowanie specjalistów na adresy e-mail
  const specialistEmails = {
    'Trener Personalny': {
      'Bartosz Smoliński': 'b.smolinski987@gmail.com',
      'Natalia Przybyło': 'natprzybylo@o2.pl',
      'Amadeusz Dziemianko': 'amadeuszdziemianko@gmail.com',
      'Robert Mistal': 'rmistal@gmail.com',
    },
    Dietetyk: 'a.wrobel.diet@gmail.com',
    Fizjoterapeuta: 'kwasnik.a@gmail.com',
  };

  // Definiowanie domyślnych wiadomości
  const defaultMessages = {
    'Trener Personalny':
      'Dzień dobry, jestem zainteresowany/a rozpoczęciem treningów personalnych i chciałbym/chciałabym dowiedzieć się więcej o ofercie oraz dostępnych terminach.',
    Dietetyk:
      'Dzień dobry, chciałbym/chciałabym skonsultować swoją dietę. Proszę o więcej informacji na temat oferty i dostępnych terminów na konsultację.',
    Fizjoterapeuta:
      'Dzień dobry, chciałbym/chciałabym umówić się na konsultację. Proszę o informację na temat dostępnych terminów i planu rehabilitacji.',
  };

  // Walidacja kroków formularza
  const validateStep1 = () => {
    const newErrors = {};
    if (!specialist) newErrors.specialist = 'Proszę wybrać specjalistę!';
    if (specialist === 'Trener Personalny' && !trainer) newErrors.trainer = 'Proszę wybrać trenera!';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateStep2 = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!contactName) newErrors.name = 'Imię jest wymagane!';
    if (!contactEmail) newErrors.email = 'Email kontaktowy jest wymagany!';
    else if (!emailRegex.test(contactEmail)) newErrors.email = 'Wprowadź poprawny email!';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Ustawianie domyślnej wiadomości oraz przypisanie adresu e-mail
  const handleSpecialistSelect = (value) => {
    setSpecialist(value);
    setCustomMessage(defaultMessages[value]);
    if (value !== 'Trener Personalny') setRecipientEmail(specialistEmails[value]);
  };

  // Ustawienie trenera
  const handleTrainerSelect = (value) => {
    setTrainer(value);
    setRecipientEmail(specialistEmails['Trener Personalny'][value]);
  };

  // Przejście do kolejnych kroków
  const handleNext = () => {
    if (step === 1 && validateStep1()) setStep(2);
  };

  // Wysłanie wiadomości e-mail przez EmailJS
  const handleSendEmail = (e) => {
    e.preventDefault();
    if (!validateStep2()) return;

    const templateParams = {
      message: customMessage,
      email: contactEmail,
      name: contactName,
      recipient: recipientEmail,
    };

    emailjs
      .send('service_z9wsmaa', 'template_kl0hege', templateParams, 'z2p8wBwOKHD_7SXKp')
      .then(() => {
        alert('Wiadomość została wysłana!');
        onClose();
      })
      .catch(() => {
        alert('Wystąpił problem przy wysyłaniu wiadomości.');
      });
  };

  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-left">
          <img src={logo} alt="Logo" className="logo" />
          <div className="background" />
        </div>
        <div className="modal-right">
          <h2>Skontaktuj się z nami</h2>

          {/* Krok 1: Wybór specjalisty */}
          {step === 1 && (
            <div className="step1">
              <label>Wybierz specjalistę:</label>
              <select value={specialist} onChange={(e) => handleSpecialistSelect(e.target.value)}>
                <option value="">Wybierz...</option>
                <option value="Trener Personalny">Trener Personalny</option>
                <option value="Dietetyk">Dietetyk</option>
                <option value="Fizjoterapeuta">Fizjoterapeuta</option>
              </select>
              {errors.specialist && <span className="error-message">{errors.specialist}</span>}

              {/* Wybór trenera, jeśli użytkownik wybierze „Trener Personalny” */}
              {specialist === 'Trener Personalny' && (
                <>
                  <label>Wybierz trenera:</label>
                  <select value={trainer} onChange={(e) => handleTrainerSelect(e.target.value)}>
                    <option value="">Wybierz trenera...</option>
                    <option value="Bartosz Smoliński">Bartosz Smoliński</option>
                    <option value="Natalia Przybyło">Natalia Przybyło</option>
                    <option value="Amadeusz Dziemianko">Amadeusz Dziemianko</option>
                    <option value="Robert Mistal">Robert Mistal</option>
                  </select>
                  {errors.trainer && <span className="error-message">{errors.trainer}</span>}
                </>
              )}

              <button onClick={handleNext}>Dalej</button>
            </div>
          )}

          {/* Krok 2: Formularz kontaktowy */}
          {step === 2 && (
            <div className="step2">
              <form onSubmit={handleSendEmail}>
                <label>Imię:</label>
                <input
                  type="text"
                  value={contactName}
                  onChange={(e) => setContactName(e.target.value)}
                  placeholder="Wprowadź swoje imię"
                />
                {errors.name && <span className="error-message">{errors.name}</span>}

                <label>Email kontaktowy:</label>
                <input
                  type="email"
                  value={contactEmail}
                  onChange={(e) => setContactEmail(e.target.value)}
                  required
                  placeholder="Wprowadź swój email"
                />
                {errors.email && <span className="error-message">{errors.email}</span>}

                <label>Wiadomość:</label>
                <textarea value={customMessage} onChange={(e) => setCustomMessage(e.target.value)} />
                <button type="submit">Wyślij wiadomość</button>
              </form>
              <button onClick={() => setStep(1)}>Cofnij do wyboru specjalisty</button>
            </div>
          )}
          <button onClick={onClose} className="close-btn">Zamknij</button>
        </div>
      </div>
    </div>
  );
};

export default ContactModal;
