import React from "react";
import "./Header.scss";
import logo from "../img/LOGO2.png";
const Header = () => {
	return (
		<header className="hero-img">
			
				<div className="hero-text">
					<img src={logo} alt="Logo firmy" className="company-logo" />
					<p className="subtext">
						Twoje miejsce na mapie zdrowia i fitnessu. Zapraszamy do naszego
						studia treningowego!
					</p>
				</div>
				<div className="hero-shadow"></div>
			
		</header>
	);
};

export default Header;
