export const reviews = [
	{
		author_name: "patrycja marks",
		author_url:
			"https://www.google.com/maps/contrib/103968154676023526824/reviews",
		language: "pl",
		original_language: "pl",
		profile_photo_url:
			"https://lh3.googleusercontent.com/a/ACg8ocLsWUlvd40tQiqpk6Uz3D5UwY95iBzAWJBBSvPa8VGOfoOCXg=s128-c0x00000000-cc-rp-mo",
		rating: 5,
		relative_time_description: "4 tygodnie temu",
		text: "Bolesławieckie Centrum Ruchu to miejsce dla osób które cenią sobie profesjonalne i indywidualne podejście do klienta. Ogromna wiedza trenera pozwoliła mi odzyskać zdrowie i mocno się rozwinąć. Siłownia idealna jeśli nie lubisz zatłoczonych miejsc i chcesz skupić się na pracy nad swoim ciałem.",
		time: 1723564923,
		translated: false,
	},
	{
		author_name: "Jagoda",
		author_url:
			"https://www.google.com/maps/contrib/112726443414937251495/reviews",
		language: "pl",
		original_language: "pl",
		profile_photo_url:
			"https://lh3.googleusercontent.com/a/ACg8ocIeP_yyYJYwNvQJbLU-dXQ9QNqfqaJpwKV2tlqs-3ZNguTsCw=s128-c0x00000000-cc-rp-mo",
		rating: 5,
		relative_time_description: "3 miesiące temu",
		text: "Do studia BCR trafiłam po długich poszukiwaniach odpowiednich osób w celu dalszej rehabilitacji po wypadku samochodowym i w końcu czuję, że jestem w odpowiednim miejscu wśród ludzi którzy wiedzą co robią. Fizjoterapia na najwyższym poziomie a trochę tych specjalistów w naszym mieście odwiedziłam.Amadeusz ma doświadczenie oraz ogromną wiedzę, którą cały czas pogłębia. Szczerze bardzo polecam treningi z Amadeuszem.",
		time: 1717087512,
		translated: false,
	},
	{
		author_name: "Ada Rogala",
		author_url:
			"https://www.google.com/maps/contrib/117263124049064162476/reviews",
		language: "pl",
		original_language: "pl",
		profile_photo_url:
			"https://lh3.googleusercontent.com/a-/ALV-UjXdtgI4qIIhxo_WeDgJVx6fbnka8X5VKymnkbDRNT_giqVowLxu=s128-c0x00000000-cc-rp-mo",
		rating: 5,
		relative_time_description: "miesiąc temu",
		text: "Wiedza, zaangażowanie i profesjonalizm. Nie ma drugiego takiego miejsca. Polecam.\nPrzyjdźcie, zobaczcie, porozmawiajcie z życzliwymi i doświadczonymi trenerami a przekonacie się, że to miejsce będzie najodpowiedniejsze dla Was. Zdrowie i dobra forma, którą możecie osiągnąć  w tym miejscu:) #trener_amek#studio_bcr\nPolecam z całego ❤️",
		time: 1723229645,
		translated: false,
	},
	{
		author_name: "Daria Michalska",
		author_url:
			"https://www.google.com/maps/contrib/106381907770539558287/reviews",
		language: "pl",
		original_language: "pl",
		profile_photo_url:
			"https://lh3.googleusercontent.com/a/ACg8ocKMJEpJpIFjc6R7r06wfUwVN8T11IyGMnpLHjk-xuVu8jJfvw=s128-c0x00000000-cc-rp-mo",
		rating: 5,
		relative_time_description: "3 miesiące temu",
		text: "Serdecznie polecam Bolesławieckie Centrum Ruchu, panuje tam przyjazna atmosfera. Trenerzy są profesjonalni i chętni do pomocy. Dzięki współpracy z trenerem Amadeuszem osiągam swoje cele treningowe.",
		time: 1716820578,
		translated: false,
	},
	{
		author_name: "Hubert Krysa",
		author_url:
			"https://www.google.com/maps/contrib/111378152880615117694/reviews",
		language: "pl",
		original_language: "pl",
		profile_photo_url:
			"https://lh3.googleusercontent.com/a/ACg8ocJapMpHCXH6OYtlIr6esd7Qd6Df_iABrEryyjbeqSdR8u5Uwg=s128-c0x00000000-cc-rp-mo",
		rating: 5,
		relative_time_description: "3 miesiące temu",
		text: "Bolesławieckie Centrum Ruchu to prawdziwa perła w naszym mieście! Od momentu, kiedy po raz pierwszy przekroczyłem ich próg, byłem pod wrażeniem profesjonalizmu i serdeczności całego zespołu. Trenerzy są niezwykle kompetentni, zawsze gotowi służyć pomocą i wsparciem, niezależnie od poziomu zaawansowania.",
		time: 1716460915,
		translated: false,
	},
];
