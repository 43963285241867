import React, { useRef, useEffect } from "react";
import "./AboutUs.scss";
import image1 from "../img/Grupowe.jpg";
import image2 from "../img/DoOnas.jpg";

export default function AboutUs() {
	const leftRef = useRef(null);
	const rightRef = useRef(null);

	useEffect(() => {
		const handleIntersection = (entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					entry.target.classList.add("animate");
				}
			});
		};

		const observer = new IntersectionObserver(handleIntersection, {
			threshold: 0.1,
		});

		if (leftRef.current) observer.observe(leftRef.current);
		if (rightRef.current) observer.observe(rightRef.current);

		return () => {
			if (leftRef.current) observer.unobserve(leftRef.current);
			if (rightRef.current) observer.unobserve(rightRef.current);
		};
	}, []);

	return (
		<div className="aboutus bg-light py-3">
			<div className="container">
				<h2 className="section-title">o Nas</h2>
				<div className="underline"></div>
				<div className="row">
					<div className="col-12 col-md-6 text-center px-3 left" ref={leftRef}>
						<div className="aboutus-card">
							<h1 className="studio-title">
								<span className="highlight">POZNAJ</span>
								<span className="subtitle">nasze studio</span>
							</h1>

							<p className="aboutus-card-text">
								Studio jest wyposażone w nowoczesny sprzęt treningowy oraz
								oferuje szeroki wachlarz zajęć, precyzyjnie dostosowanych do
								indywidualnych potrzeb klientów. Wykwalifikowani trenerzy
								personalni zapewniają profesjonalne i spersonalizowane
								podejście, dostosowując plan treningowy oraz intensywność
								ćwiczeń do możliwości i celów każdego, gwarantując skuteczność
								oraz bezpieczeństwo treningu.
							</p>
						</div>
					</div>
					<div
						className="col-12 col-md-6 text-center px-3 right"
						ref={rightRef}>
						<div className="image-container">
							<div className="skew-container-one">
								<img
									src={image1}
									alt="Gym equipment 1"
									className="image image1"
								/>
							</div>
							<div className="skew-container-two">
								<img
									src={image2}
									alt="Gym equipment 2"
									className="image image2"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
