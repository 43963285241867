// Komponent: TrainerCard.jsx
import React, { useEffect, useState, useRef } from "react";
import "./Trainers.scss";

import { useLocation } from "react-router-dom";

const TrainerCard = ({ trainers }) => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const trainerId = queryParams.get("id");
	const [visibleCards, setVisibleCards] = useState([]);
	const trainerRefs = useRef([]);

	// Ustawienie widocznych kart
	useEffect(() => {
		const allCards = trainers.map((_, index) => index);
		setVisibleCards(allCards);
	}, [trainers]);

	// Przewijanie do odpowiedniego trenera po załadowaniu komponentu
	useEffect(() => {
		if (trainerId) {
			const idIndex = trainers.findIndex(
				(trainer) => trainer.id === parseInt(trainerId)
			);
			if (idIndex >= 0 && trainerRefs.current[idIndex]) {
				trainerRefs.current[idIndex].scrollIntoView({
					behavior: "smooth",
					block: "center",
				});
			}
		}
	}, [trainerId, trainers]);

	return (
		<div className="trainer-card-container">
			{trainers.map((trainer, index) => (
				<div
					key={trainer.id}
					className={`trainer-card-details ${
						visibleCards.includes(index) ? "visible pop" : ""
					}`}
					ref={(el) => (trainerRefs.current[index] = el)} // Używaj indexu zamiast ID
					style={{
						opacity: visibleCards.includes(index) ? 1 : 0,
						transform: visibleCards.includes(index)
							? "translateY(0)"
							: "translateY(50px)",
						transition: `opacity 0.8s ease-out, transform 0.8s ease-out ${
							index * 0.2
						}s`,
						marginBottom: "20px",
					}}>
					<div
						className="trainer-card-background"
						style={{ backgroundImage: `url(${trainer.background})` }}></div>
					<h2 className="trainer-title">{trainer.role}</h2>
					<div className="trainer-card-content">
						<div className="trainer-card-image">
							<img src={trainer.image} alt={trainer.name} />
						</div>
						<div className="trainer-card-info">
							<h3 className="trainer-name">{trainer.name}</h3>
							<div className="trainer-card-text">
								<p>{trainer.description}</p>
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default TrainerCard;
