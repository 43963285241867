import React, { useState } from "react";
import Home from "./pages/Home";
import Squad from "./pages/Squad";
import Galleryy from "./pages/Galleryy";
import Prices from "./pages/Prices";
import ShopPage from "./pages/ShopPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import MessageIcon from "./components/Icon";
import NavBar from "./components/NavBar";
import "bootstrap/dist/css/bootstrap.min.css";
import ContactModal from "./components/ContactModal";
import InfoBanner from "./components/InfoBaner";
import CookieConsent from "./components/CookieConset";
import ScrollToTop from "./components/Scroll";
import Blog from "./pages/Blog";
function App() {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isBannerVisible, setIsBannerVisible] = useState(false);

	// Funkcja przełączająca widoczność banera
	const toggleBanner = () => {
		setIsBannerVisible(!isBannerVisible);
	};
	// Funkcja otwierająca modal
	const openModal = () => {
		setIsModalOpen(true);
	};

	// Funkcja zamykająca modal
	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<Router>
			<NavBar toggleBanner={toggleBanner} />{" "}
			{/* Przekazujemy toggleBanner jako props */}
			{isBannerVisible && <InfoBanner isVisible={isBannerVisible} />}
			<ScrollToTop></ScrollToTop>
			<Routes>
				<Route path="/" element={<Home />} /> {/* Strona główna */}
				<Route path="/trainer/:id" element={<Squad />} /> {/* Strona Squad */}
				<Route path="/trainers" element={<Squad />} /> {/* Strona Squad */}
				<Route path="/gallery" element={<Galleryy />} /> {/* Strona Galeria */}
				<Route path="/blog" element={<Blog />} /> {/* Strona Galeria */}
				<Route path="/pricing" element={<Prices />} /> {/* Strona Cennik */}
				<Route path="/shop" element={<ShopPage />} /> {/* Strona Sklep */}
			</Routes>
			<Footer /> {/* Stopa strony */}
			{/* Modal kontaktowy */}
			<ContactModal isOpen={isModalOpen} onClose={closeModal} />
			{/* Ikona wiadomości, przekazujemy funkcję openModal */}
			<MessageIcon onClick={openModal} />
			<CookieConsent></CookieConsent>
		</Router>
	);
}

export default App;
