import React, { useEffect, useState } from "react";
import "../components/BlogSection.scss";
import logo from "../img/LOGO2.png";
import article from "../treiners/article";

const BlogSection = () => {
	const [articles, setArticles] = useState([]);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [showWelcome, setShowWelcome] = useState(true);

	useEffect(() => {
		setArticles(article);
	}, []);

	const handleNext = () => {
		setCurrentIndex((prevIndex) => (prevIndex + 1) % articles.length);
		setShowWelcome(false);
	};

	const handlePrev = () => {
		setCurrentIndex((prevIndex) => (prevIndex - 1 + articles.length) % articles.length);
		setShowWelcome(false);
	};

	const handleWelcomeClick = () => {
		setShowWelcome(false);
	};

	const handleArticleClick = (index) => {
		setCurrentIndex(index);
		setShowWelcome(false);
		// Automatyczne przewijanie do tytułu artykułu
		const articleTitleElement = document.querySelector(".article-title-left");
		articleTitleElement?.scrollIntoView({ behavior: "smooth", block: "center" });
	};

	return (
		<div className="blog-section">
			<header className="blog-header">
				<h1 className="blog-title">BLOG</h1>
				<img src={logo} alt="Logo" className="blog-logo" />
			</header>

			<div className="article-section">
				<div className="blog-carousel">
					<div className="carousel-content">
						{showWelcome ? (
							<div className="blog-welcome" onClick={handleWelcomeClick}>
								<h2 className="blog-welcome-title">Witaj na naszym blogu!</h2>
								<p className="blog-welcome-text">
									Skupiamy się na zdrowym stylu życia: rehabilitacji, zdrowiu, treningu i diecie.
								</p>
							</div>
						) : (
							<div className={`article-content fade-in`}>
								<h2 className="article-title-left">{articles[currentIndex]?.title}</h2>
								<p className="article-description">{articles[currentIndex]?.description}</p>
							</div>
						)}

						<div className="blog-articles">
							{articles.map((article, index) => (
								<div
									className={`article-slide ${index === currentIndex ? "active" : ""}`}
									key={index}
									onClick={() => handleArticleClick(index)}
									style={{
										backgroundImage: `url(${article.image})`,
										backgroundSize: "cover",
										backgroundPosition: "center",
									}}
								>
								</div>
							))}
						</div>
					</div>

					<div className="arrow-container">
						<button className="carousel-arrow left-arrow" onClick={handlePrev}>
							&#8249;
						</button>
						<button className="carousel-arrow right-arrow" onClick={handleNext}>
							&#8250;
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BlogSection;
