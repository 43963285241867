import React from "react";
import "../components/Footer.scss"; // Stylizacja w osobnym pliku CSS
import log1 from "../img/loga/1.jpg";
import log2 from "../img/loga/2.png";
import log3 from "../img/loga/3.jpg";
import log4 from "../img/loga/4.jpg";
import log5 from "../img/loga/5.png";
import logoFirmy from "../img/loga/LOGO.png";

const Footer = () => {
	const sponsorLogos = [log1, log2, log3, log4, log5];

	return (
		<footer className="footer">
			{/* Dwa główne kwadratowe boxy */}
			<div className="footer-main">
				{/* Lewy box z logo firmy */}
				<div className="footer-box footer-left-box">
					<img src={logoFirmy} alt="Logo Firmy" className="company-logo" />
				</div>

				{/* Prawy box z informacjami */}
				<div className="footer-box footer-right-box">
					<h2>Partnerzy</h2>
					<div className="sponsor-logos">
						{sponsorLogos.map((logo, index) => (
							<div key={index} className="circle-logo-container">
								<img
									src={logo}
									alt={`Sponsor ${index + 1}`}
									className="circle-logo"
								/>
							</div>
						))}
					</div>

					<div className="footer-contact">
						<h3>Kontakt</h3>
						<p>Telefon: +48 535 202 813</p>
						<p>Email: studiotreningowebcr@gmail.com</p>
					</div>

					<div className="footer-copyright">
						<p>© 2024 Bolesławieckie Centrum Ruchu. Strona stworzona</p>
						<a
							href="https://www.linkedin.com/in/mateuszwoloszyk/"
							target="_blank">
							<i className="fa-brands fa-linkedin"></i>
						</a>
					</div>
				</div>
			</div>

			{/* Dwa prostokątne boxy na dole */}
			<div className="footer-social">
				<div className="social-box">
					<a
						href="https://www.facebook.com/people/Boles%C5%82awieckie-Centrum-Ruchu-Rehabilitacja-Zdrowie-Trening-Dieta/100088354635963/"
						target="_blank"
						rel="noreferrer">
						<i className="fa-brands fa-facebook"></i>
					</a>
				</div>
				<div className="social-box">
					<a
						href="https://www.instagram.com/STUDIO_BCR"
						target="_blank"
						rel="noreferrer">
						<i className="fa-brands fa-instagram"></i>
					</a>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
